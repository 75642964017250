import React, { useEffect, useState } from "react";
import AntTable from "../../ui/table/Table";
import axios from "axios";

function ViewCompanyProfiles() {
  const [companies, setCompanies] = useState([]);

  const columns = [
    {
      dataField: "companyName",
      text: "Company Name",
    },
    {
      dataField: "companyDescription",
      text: "Company Description",
    },
    {
      dataField: "fieldOfBusiness",
      text: "Field of Business",
    },
    {
      dataField: "companySize",
      text: "Company Size",
    },
    {
      dataField: "businessProblem",
      text: "Business Problem",
    },
    {
      dataField: "preferredFieldOfStudy",
      text: "Preferred Field of Study",
      formatter: (cell) => cell.map((item, index) => <div key={index}>- {item}</div>), // Each item in new line
      headerStyle: { minWidth: '250px' }, // Increase header width
      style: { minWidth: '250px' } // Ensure cell content fits within the minWidth
    },
    {
      dataField: "sourceOfInformation",
      text: "Source of Information",
    },
    {
      dataField: "reasonForParticipation",
      text: "Reason for Participation",
    },
    {
      dataField: "address.country",
      text: "Country",
    },
    {
      dataField: "address.city",
      text: "City",
    },
    {
      dataField: "address.street",
      text: "Street",
    },
    {
      dataField: "address.houseNumber",
      text: "House Number",
    },
    {
      dataField: "address.website",
      text: "Website",
      formatter: (cell) => cell ? <a href={cell} target="_blank" rel="noopener noreferrer">{cell}</a> : '', // Link to the website
    },
    {
      dataField: "contactPerson.firstName",
      text: "Contact First Name",
    },
    {
      dataField: "contactPerson.lastName",
      text: "Contact Last Name",
    },
    {
      dataField: "contactPerson.email",
      text: "Contact Email",
    },
    {
      dataField: "contactPerson.phone",
      text: "Contact Phone",
    },
    {
      dataField: "semester",
      text: "Semester",
    },
    {
      dataField: "ndaRequired",
      text: "NDA Required",
    },
  ];

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get("/api/company");
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  return (
    <div className="d-flex flex-column align-items-center p-5">
      <AntTable data={companies} columns={columns} />
    </div>
  );
}

export default ViewCompanyProfiles;
