import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./ant-components/routes/PrivateRoute";
import PrivateAdminRoute from "./ant-components/routes/PrivateAdminRoute";

import Dashboard from "./ant-components/pages/dashboard/Dashboard";
import CreateProfile from "./ant-components/pages/create-profile/CreateProfile";

import SSOLogin from "./ant-components/auth/SSOLogin";
import LoginView from "./ant-components/pages/landing/LoginView";

import "./App.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import AntHeader from "./ant-components/layout/header/Header";
import AntFooter from "./ant-components/layout/footer/Footer";
import ViewStudentProfiles from "./ant-components/pages/view-student-profiles/ViewStudentProfiles";
import AntBanner from "./ant-components/ui/banner/Banner";
import { RegisterCompany } from "./ant-components/pages/register-company/RegisterCompany";
import Terms from "./ant-components/pages/create-profile/Terms";
import ViewCompanyProfiles from "./ant-components/pages/view-company-profiles/ViewCompanyProfiles";
import Posters from "./ant-components/pages/posters/PostersPage";

function App() {
  return (
    <div className="app-container">
      <Router>
        <AntBanner />
        <AntHeader />
        <div className="content-container">
          <Switch>
            <Route exact path="/" component={LoginView} />
            <Route exact path="/ssologin" component={SSOLogin} />
            <Route exact path="/register-company" component={RegisterCompany} />
            <Route path="/posters" component={Posters} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/terms" component={Terms} />
            <PrivateRoute
              exact
              path="/create-profile"
              component={CreateProfile}
            />
            <PrivateRoute
              exact
              path="/view-student-profiles"
              component={ViewStudentProfiles}
            />
            <PrivateRoute
              exact
              path="/view-company-profiles"
              component={ViewCompanyProfiles}
            />
          </Switch>
        </div>
        <AntFooter />
      </Router>
    </div>
  );
}

export default App;
