import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SignupButton from "../../ui/button/SignupButton";
const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

const WhyAttend = () => {
  const history = useHistory();

  const params = new URLSearchParams({
    target: `${window.location.origin}/ssologin`,
  });

  const headingStyle = {
    fontStyle: "italic",
    fontWeight: "bold",
  };

  const headingStyle2 = {
    textAlign: "center",
    fontWeight: 600,
    padding: "40px",
    fontSize: "30px",
    color: "#313131",
    height: "75px",
  };

  const listItemStyle = {
    fontWeight: 400,
    fontSize: "17px",
    marginBottom: "8px",
    padding: "10px 0px",
  };

  const ulStyle = {
    paddingTop: "40px",
    textAlign: "left",
  };

  const HandleSignUpButton = () => {
    return (
      <div>
        <a href={`${backendUrl}/api/users/shibboleth/login/tum?${params}`}>
          <SignupButton text="SIGN UP WITH TUM CREDENTIALS" variant="primary" />
        </a>
      </div>
    );
  };

  const HandleSignUpCompaniesButton = () => {
    return (
      <div>
        <SignupButton
          text="REGISTER YOUR COMPANY WITH US"
          variant="secondary"
          onClick={() => history.push("/register-company")}
        />
      </div>
    );
  };

  return (
    <div>
      <h1 style={headingStyle2}>Don’t miss the chance to be part of 1.000+</h1>
      <section id="why-attend" className="why-attend-container">
        <div className="container">
          <div
            className="why-attend-left-section"
            style={{
              borderRadius: "10px",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {" "}
            <div>
              <h2 style={{ fontWeight: 800 }}>
                Why should I attend as a student?
              </h2>
              <ul style={ulStyle}>
                <li style={listItemStyle}>
                  Meet the German economic powerhouse
                </li>
                <li style={listItemStyle}>
                  Get to know corporate business and culture
                </li>
                <li style={listItemStyle}>
                  Exchange ideas with other students & TUM community
                </li>
                <li style={listItemStyle}>
                  Take the opportunity to solve real-life problems
                  (challenge-based learning)
                </li>
              </ul>
            </div>
            {HandleSignUpButton()}
          </div>
          <div
            className="why-attend-right-section"
            style={{
              borderRadius: "10px",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <h2 style={{ fontWeight: 800 }}>What can I gain as a company?</h2>
              <ul style={ulStyle}>
                <li style={listItemStyle}>
                  Connect with German & International TUM Talents
                </li>
                <li style={listItemStyle}>
                  Benefit from direct access to TUM & its community
                </li>
                <li style={listItemStyle}>
                  Meet potential future employees in the environment of the
                  company
                </li>
                <li style={listItemStyle}>
                  Learn from young TUM Talents and their innovative
                  problem-solving approach
                </li>
              </ul>
            </div>

            {HandleSignUpCompaniesButton()}
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyAttend;
