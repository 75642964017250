import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import axios from "axios";
import { config as fontAwesomeConfig } from "@fortawesome/fontawesome-svg-core";

import App from "./App";
import {store, persistor} from "./store";
import { logoutUser, setCurrentUser } from "./actions/authActions";
import { PersistGate } from 'redux-persist/integration/react'


import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

// required for Content-Security-Policy
fontAwesomeConfig.autoAddCss = false;

function restoreClaims() {
  if (localStorage.length !== 0) {
    const storageItem = localStorage.getItem("user");
    if (!storageItem) {
      localStorage.clear();
      return;
    }

    const { id, role, exp } = JSON.parse(storageItem);

    if (!id || !role || !exp || parseInt(exp) * 1000 < Date.now()) {
      store.dispatch(logoutUser());
      return;
    }

    store.dispatch(setCurrentUser({ id, role }));
  }
}

async function axiosSetup() {
  // set backend base url; defaults to client site
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
  // send cookies during for cross origin backend
  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === 401) {
      store.dispatch(logoutUser());
    }

    return Promise.reject(error);
  });

  // get CSRF token
  const res = await axios.get("/api/auth/csrf");
  axios.defaults.headers.common["X-CSRF-Token"] = res.data.token;
}

restoreClaims();
axiosSetup();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <App />
      </PersistGate>
      
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
