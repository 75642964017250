import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";

const AdminProfileActions = () => {
  return (
    <div className="mb-4">
      <h6>Student Information:</h6>
      <div className="dash-buttons">
        <Link to="/view-student-profiles">
          <Button
            type="default"
            icon={<UserOutlined />}
            className="ant-btn-light"
          >
            View Student profiles
          </Button>
        </Link>
      </div>
      <h6>Company Information:</h6>
      <div className="dash-buttons">
        <Link to="/view-company-profiles">
          <Button
            type="default"
            icon={<UserOutlined />}
            className="ant-btn-light"
          >
            View Company Info
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default AdminProfileActions;
