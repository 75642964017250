import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import AntBanner from "../../ui/banner/Banner";
import { useLocation } from "react-router-dom";
import config from "../../../config";

const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

function AntHeader() {
  const location = useLocation();

  const scroll1 = (link) => {
    const section = document.querySelector(link);
    section.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const params = new URLSearchParams({
    target: `${window.location.origin}/ssologin`,
  });

  const displayMenu = location?.pathname == "/" ? true : false;

  return (
    <div
      style={
        {
          // position: "sticky",
          // top: 0, // Stick to the top of the viewport
          // zIndex: 999, // Set the z-index to ensure it's above other content
        }
      }
    >
      <Navbar
        expand="lg"
        variant="dark"
        className="bg-body-tertiary "
        style={{
          padding: "10px 40px",
          background: "linear-gradient(to right, #024478, #0E0E0E",
        }}
      >
        <Link to="/">
          <div
            style={{
              padding: "5px",
            }}
          >
            <img
              src={"./1000plus_Logo.png"}
              alt="Logo"
              style={{ height: "80px", width: "auto" }}
            />
          </div>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="justify-content-between"
            style={{ width: "100%", alignItems: "center" }}
          >
            {displayMenu ? (
              <Nav
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "400",
                }}
              >
                <Nav.Link
                  style={{
                    color: "white",
                    fontSize: "16px",
                    padding: "5px 20px",
                  }}
                  onClick={(e) => scroll1("#project-week-info")}
                >
                  Description
                </Nav.Link>
                <Nav.Link
                  style={{
                    color: "white",
                    fontSize: "16px",
                    padding: "5px 20px",
                  }}
                  onClick={(e) => scroll1("#why-attend")}
                >
                  Benefits
                </Nav.Link>
                <Nav.Link
                  style={{
                    color: "white",
                    fontSize: "16px",
                    padding: "5px 20px",
                  }}
                  onClick={(e) => scroll1("#insights")}
                >
                  Insights
                </Nav.Link>

                {/* New Posters tab */}
                <Nav.Link
                  as={Link}
                  to="/posters"
                  style={{
                    color: "white",
                    fontSize: "16px",
                    padding: "5px 20px",
                  }}
                >
                  Working Results
                </Nav.Link>

                <Nav.Link
                  style={{
                    color: "white",
                    fontSize: "16px",
                    padding: "5px 20px",
                  }}
                  onClick={(e) => scroll1("#timeline")}
                >
                  Timeline
                </Nav.Link>
                <Nav.Link
                  style={{
                    color: "white",
                    fontSize: "16px",
                    padding: "5px 20px",
                  }}
                  onClick={(e) => scroll1("#FAQ")}
                >
                  FAQ
                </Nav.Link>
                {/* {config.featureFlags.showStudentLoginButton ? (
                  <div style={{ padding: "0 10px" }}>
                    <a
                      href={`${backendUrl}/api/users/shibboleth/login/tum?${params}`}
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "2px solid white", // Add white border
                        borderRadius: "5px", // Add rounded corners
                        color: "white", // Set text color to white
                        padding: "10px", // Adjust padding as needed
                        textDecoration: "none", // Remove default underline
                        display: "inline-block", // Ensure inline-block display for padding to work correctly
                      }}
                    >
                      LOGIN
                    </a>
                  </div>
                ) : null} */}
              </Nav>
            ) : (
              <div></div>
            )}

            <div
              style={{
                padding: "10px",
              }}
            >
              <img
                src={"./TUM_Logo.png"}
                alt="TUM Logo"
                style={{ height: "40px", width: "auto" }}
              />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default AntHeader;
