import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Button, Spin, Alert, Typography } from "antd";
import StudentProfileActions from "./StudentProfileActions";
import axios from "axios";
import {
  clearForDashboard,
  getCurrentProfile,
} from "../../../actions/profileActions";
import Terms from "../create-profile/Terms";
import AdminProfileActions from "./AdminProfileActions";
// import Unregistered from './../create-profile/Unregistered';


const { Title, Text } = Typography;

function Dashboard(props) {
  const [err, setErr] = useState(null);
  const [role, setRole] = useState("");
  const [fetchRoleIsLoading, setFetchRoleIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { profile, loading } = useSelector((state) => state.profile);
  const state = props?.location?.state || false;

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCurrentProfile());
        await dispatch(clearForDashboard());
        await fetchUserRole();
      } catch (error) {
        setErr(error);
      }
    }
    fetchData();
  }, [dispatch]);

  const fetchUserRole = async () => {
    setFetchRoleIsLoading(true);

    try {
      const res = await axios.get("/api/auth/role");
      if (res.status >= 400) {
        throw new Error("An error occurred while fetching user role.");
      }
      const userdata = res.data;
      setRole(userdata.role);
    } catch (error) {
      setErr(error);
    } finally {
      setFetchRoleIsLoading(false);
    }
  };

  if (err) {
    return (
      <Alert message="Error" description={err.message} type="error" showIcon />
    );
  }

  let dashboardContent;

  if (profile === null || loading || fetchRoleIsLoading) {
    dashboardContent = (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
        <Spin size="large" />;
      </div>
    );
  } else {
    if (Object.keys(profile).length > 0 && profile?.registeredIn == "WS25") {
      if (role === "Student") {
        dashboardContent = <StudentProfileActions state={state} />;
      } else if (role === "Admin") {
        dashboardContent = (
          <div>
            <Title level={3}>Welcome, {profile.firstname}!</Title>
            <AdminProfileActions />
          </div>
        );
      }
    } else {
      if (role === "Student") {
        dashboardContent = (
          <div>
            <Terms />
          </div>
        );
      } 
    }
  }

  return (
    <div className="dashboard">
      <div className="container">
        <Col span={24}>{dashboardContent}</Col>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  clearForDashboard: PropTypes.func.isRequired,
};

export default Dashboard;
